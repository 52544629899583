.CompletedProjects {
  background: url(../../img/path_complate.jpg) center center;
  background-size: cover;
  .shortDesc {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
    overflow: hidden;
    color: #7a828b;
    font-weight: bold;
  }
  .item {
    background: #fff;
    box-shadow: 0px 3px 7px rgba(203, 203, 203, 0.36);
    filter: drop-shadow(0px 3px 7px rgba(203, 203, 203, 0.38));
    border: 1px solid #f5f5f5;
    border-radius: 20px;
    transition: all 0.5s ease-in-out;
    height: auto;
    &::after {
      transition: all 0.5s ease-in-out;
      content: "";
      border: 0.5px solid transparent;
      height: 1px;
      width: 50px;
      display: block;
      position: absolute;
      bottom: 0;
      left: calc((248px - 50px) / 2);
    }
    &:hover {
      transition: all 0.5s ease-in-out;
      box-shadow: 0px 6px 14px rgba(86, 120, 0, 0.19);
      &::after {
        border: 0.5px solid #28a745;
      }
    }
    img {
      border-radius: 20px;
    }
  }
}

@media (max-width: 500px) {
  .completedContentWrapper {
    margin-bottom: 20px !important;
  }
  .moreBtn {
    margin-top: 20px;
  }
  .CompletedProjects:lang(ar) {
    .completedContentWrapper {
      margin-right: 50px;
    }
  }
}

.CompletedProjects:lang(ar) {
  font-family: "Cairo", sans-serif;
  .arrow-img-wrapper {
    direction: rtl;
    text-align: right;
    .arrowCompleted {
      margin-left: 0px !important;
      transform: rotate(180deg);
      margin-right: 15px;
    }
  }
}
