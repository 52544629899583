.Department {
  .item {
    cursor: pointer;
    width: 100%;
    height: auto;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 6px 14px rgba(86, 120, 0, 0.19);
    transition: all 0.5s ease-in-out;
    &::after {
      transition: all 0.5s ease-in-out;
      content: "";
      border: 0.5px solid transparent;
      height: 50px;
      display: block;
      position: absolute;
      left: 14px;
      top: calc((200px - 50px) / 2);
    }
    &::before {
      transition: all 0.5s ease-in-out;
      content: "";
      border: 0.5px solid transparent;
      height: 50px;
      display: block;
      position: absolute;
      right: 14px;
      top: calc((200px - 50px) / 2);
    }
    &:hover {
      transition: all 0.5s ease-in-out;
      box-shadow: 0px 1.5px 27px rgba(86, 120, 0, 0.27);
      &::after {
        border: 0.5px solid #28a745;
      }
      &::before {
        border: 0.5px solid #28a745;
      }
    }
  }
}

@media (max-width: 500px) {
  .departmentMobile {
    display: flex;
    justify-content: center;
  }
  .Department:lang(ar) {
    .departmentContentWrapper {
      margin-right: 10px;
      text-align: center;
    }
  }
  .Department:lang(en) {
    .departmentContentWrapper {
      margin-left: 10px;
      text-align: center;
    }
  }
}

.Department:lang(ar) {
  direction: rtl;
  text-align: right;
  font-family: "Cairo", sans-serif;
}
