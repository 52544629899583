.ProjectsInProgress {
  background: url(../../img/bg_progress.png) center center no-repeat;
  background-size: cover;
  min-height: 100vh;
  .shortDesc {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
    overflow: hidden;
    color: #7a828b;
    font-weight: bold;
  }
  .progressDisblay {
    display: none;
  }
  .item {
    background: #fff;
    margin-bottom: 55px;
    @media (max-width: 576px) {
      margin-bottom: 75px !important ;
    }
    box-shadow: 0px 3px 7px rgba(203, 203, 203, 0.36);
    filter: drop-shadow(0px 3px 7px rgba(203, 203, 203, 0.38));
    border: 1px solid #f5f5f5;
    border-radius: 20px;
    transition: all 0.5s ease-in-out;
    height: auto;
    &::after {
      transition: all 0.5s ease-in-out;
      content: "";
      border: 0.5px solid transparent;
      height: 1px;
      width: 50px;
      display: block;
      position: absolute;
      top: 0;
      left: calc((249px - 50px) / 2);
    }
    &:hover {
      transition: all 0.5s ease-in-out;
      box-shadow: 0px 6px 14px rgba(86, 120, 0, 0.19);
      &::after {
        border: 0.5px solid #28a745;
      }
    }
    img {
      border-radius: 20px;
    }
  }
}

@media (max-width: 500px) {
  .progressMobile {
    display: flex;
    justify-content: center;
  }
  .ProjectsInProgress:lang(ar) {
    .progressContentWrapper {
      margin-right: 60px;
    }
  }
  .ProjectsInProgress:lang(en) {
    .progressContentWrapper {
      margin-left: 10px;
      text-align: center;
    }
  }
}

.ProjectsInProgress:lang(ar) {
  direction: rtl;
  text-align: right;
  font-family: "Cairo", sans-serif;
  
  .arrowProgress {
    transform: rotate(180deg);
    margin-right: 10px;
    margin-left: 0px !important;
  }
}
