.projectSummary {
  position: relative;
  .path-title {
    position: absolute;
    top: 10px;
    left: -30px;
  }
  .shortDesc {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
    overflow: hidden;
  }
  .item {
    border-color: #f5f5f5;
    filter: drop-shadow(0px 3px 7px rgba(203, 203, 203, 0.38));
    height: 228px;
    @media (max-width: 576px) {
      height: auto;
    }
    border-radius: 20px;
    transition: all 0.5s ease-in-out;
    &::after {
      transition: all 0.5s ease-in-out;
      content: "";
      border: 0.5px solid transparent;
      height: 50px;
      display: block;
      position: absolute;
      right: 0;
      top: calc((228px - 50px) / 2);
    }
    &:hover {
      transition: all 0.5s ease-in-out;
      box-shadow: 0px 6px 14px rgba(86, 120, 0, 0.19);
      &::after {
        border: 0.5px solid #28a745;
      }
    }

    img {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
  }
}

.projectSummary:lang(ar) {
  direction: rtl;
  text-align: right;
  .item:lang(ar) {
    border-color: #f5f5f5;
    filter: drop-shadow(0px 3px 7px rgba(203, 203, 203, 0.38));
    height: 228px;
    @media (max-width: 576px) {
      height: auto;
    }
    border-radius: 20px;
    transition: all 0.5s ease-in-out;
    &::after {
      border: none;
    }
    &::before {
      transition: all 0.5s ease-in-out;
      content: "";
      border: 0.5px solid transparent;
      height: 50px;
      display: block;
      position: absolute;
      left: 0;
      top: calc((228px - 50px) / 2);
    }
    &:hover {
      transition: all 0.5s ease-in-out;
      box-shadow: 0px 6px 14px rgba(86, 120, 0, 0.19);
      &::before {
        border: 0.5px solid #28a745;
      }
      &::after {
        border: none;
      }
    }

    img {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
}
