/* Color schemes */
.demo-adsila {
  --color-text: #57585c;
  --color-background: #fff;
  --color-link: #5d93d8;
  --color-link-hover: #423c2b;
}

.demo-tsula {
  --color-text: #fafae5;
  --color-background: #222328;
  --color-link: #4f515a;
  --color-link-hover: #fafae5;
}

.demo-inola {
  --color-text: #8c8c8c;
  --color-background: #1f1e1e;
  --color-link: #e5d338;
  --color-link-hover: #8c8c8c;
}

.demo-ama {
  --color-text: #9a9da7;
  --color-background: #fff;
  --color-link: #32343c;
  --color-link-hover: #9a9da7;
}

.demo-yona {
  --color-text: #000;
  --color-background: #4520f3;
  --color-link: #fff;
  --color-link-hover: #000;
}

.demo-salal {
  --color-text: #b5b5b5;
  --color-background: #222325;
  --color-link: #515152;
  --color-link-hover: #fff;
}

.demo-dustu {
  --color-text: #fff;
  --color-background: #161616;
  --color-link: #565656;
  --color-link-hover: #fff;
}

.demo-mohe {
  --color-text: #171754;
  --color-background: #2b34f5;
  --color-link: #9ad6fc;
  --color-link-hover: #171754;
}

/* Fade effect */
.js body {
  opacity: 0;
  transition: opacity 0.3s;
}

.js body.render {
  opacity: 1;
}

a {
  text-decoration: none;
  color: #5d93d8;
  color: var(--color-link);
  outline: none;
}

a:hover,
a:focus {
  color: #423c2b;
  color: var(--color-link-hover);
  outline: none;
}

.hidden {
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
  pointer-events: none;
}

/* Icons */
.icon {
  display: block;
  width: 1.5em;
  height: 1.5em;
  margin: 0 auto;
  fill: currentColor;
}

.icon--keyboard {
  display: none;
}

main {
  position: relative;
  width: 100%;
}

.content {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0 auto;
  background-color: #fff !important;
  width: 100%;
  height: 100%;
}

.content--fixed {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: grid;
  align-content: space-between;
  width: 100%;
  max-width: none;
  height: 100vh;
  pointer-events: none;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto 4em;
  grid-template-areas:
    "header ..."
    "... ..."
    "github demos";
}

.content--fixed a {
  pointer-events: auto;
}

/* Header */
.codrops-header {
  position: relative;
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-items: center;
  align-self: start;
  grid-area: header;
  justify-self: start;
}

.codrops-header__title {
  font-size: 1em;
  font-weight: normal;
  margin: 0;
  padding: 0.75em 0;
}

.info {
  margin: 0 0 0 1.25em;
  color: var(--color-link-hover);
}

.github {
  display: block;
  align-self: end;
  grid-area: github;
  justify-self: start;
}

.demos {
  position: relative;
  display: block;
  align-self: end;
  text-align: center;
  grid-area: demos;
}

.demo {
  margin: 0 0.15em;
}

.demo span {
  white-space: nowrap;
  letter-spacing: 0.1em;
  text-transform: capitalize;
  pointer-events: none;
}

.demo span::before {
  content: "#";
}

a.demo--current {
  pointer-events: none;
  border: 1px solid;
}

/* Top Navigation Style */
.codrops-links {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 1em 0 0;
  text-align: center;
  white-space: nowrap;
}

.codrops-icon {
  display: inline-block;
  margin: 0.15em;
  padding: 0.25em;
}

/* Common styles for the menus */
.menu {
  position: relative;
  z-index: 10;
}

.menu__item {
  line-height: 1;
  position: relative;
  display: block;
  margin: 1em 0;
  outline: none;
}

.menu__item-name,
.menu__item-label {
  position: relative;
  display: inline-block;
}

.menu__item-name {
  font-size: 1.25em;
}

.menu__item-label {
  margin: 0 0 0 0.5em;
}

@media screen and (min-width: 50em) {
  .icon--keyboard {
    position: absolute;
    right: 0.55em;
    bottom: -30%;
    display: block;
    width: 54px;
    height: 46px;
    fill: var(--color-link);
  }
  .demos {
    display: flex;
    padding-right: 80px;

    justify-self: end;
  }
  .demo {
    display: block;
    width: 17px;
    height: 17px;
    margin: 0 5px 0 0;
    border: 2px solid;
    border-radius: 2px;
  }
  a.demo--current {
    background: currentColor;
  }
  .demo span {
    position: absolute;
    top: -0.2em;
    right: 100%;
    display: none;
    margin: 0 1em 0 0;
  }
  .demo--current span {
    display: block;
  }
}

@media screen and (max-width: 50em) {
  html,
  body {
    overflow-x: hidden;
    width: 100vw;
    height: 100%;
  }
  .content {
    height: auto;
    min-height: 0;
    padding-bottom: 10em;
  }
  .content--fixed {
    position: relative;
    z-index: 1000;
    display: block;
    padding: 0.85em;
  }
  .codrops-header {
    flex-direction: column;
    align-items: center;
  }
  .codrops-header__title {
    font-weight: bold;
    padding-bottom: 0.25em;
    text-align: center;
  }
  .github {
    display: block;
    margin: 1em auto;
  }
  .codrops-links {
    margin: 0;
  }
}
