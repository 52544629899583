
      #whatsapp_chat_widget{
            display: block
        }
        .wa-chat-box-content-send-btn-text{
            margin-left: 8px;
            margin-right: 8px;
            z-index: 1;
            color: rgb(255, 255, 255);
        }
        .wa-chat-box-content-send-btn-icon{
            width: 16px;
            height: 16px;
            fill: rgb(255, 255, 255);
            z-index: 1;
            flex: 0 0 16px;
        }
        .wa-chat-box-content-send-btn{
            text-decoration: none;
            color: rgb(255, 255, 255)!important;
            font-size: 15px;
            font-weight: 700;
            line-height: 20px;
            cursor: pointer;
            position: relative;
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            -webkit-appearance: none;
            padding: 8px 12px;
            border-radius: 25px;
            border-width: initial;
            border-style: none;
            border-color: initial;
            border-image: initial;
            background-color: #0a5f54 !important;
            margin: 20px;
            overflow: hidden;
        }
        .wa-chat-box-send{
            background-color:white;

        }
        .wa-chat-box-content-chat-brand{        
            font-size: 13px;
            font-weight: 700;
            line-height: 18px;
            color: rgba(0, 0, 0, 0.4);
        }
        .wa-chat-box-content-chat-welcome{        
            font-size: 14px;
            line-height: 19px;
            margin-top: 4px;
            color: rgb(17, 17, 17);
        }
        .wa-chat-box-content-chat{
            background-color: white;
            display: inline-block;
            margin: 20px;
            padding: 10px;
            border-radius: 10px;
        }
        .wa-chat-box-content{
            background: url('https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png');
            
        }
        .wa-chat-bubble-close-btn{
            cursor: pointer;
            position: absolute;
            right: 20px;
            top: 20px;
        }
        .wa-chat-box-brand-text{
            margin-left: 20px;
        }
        .wa-chat-box-brand-name{
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
        }
        .wa-chat-box-brand-subtitle{
            font-size: 13px;
            line-height: 18px;
            margin-top: 4px;
        }
        
        .wa-chat-box-header{
            height: 100px;
            max-height: 100px;
            min-height: 100px;
            background-color: #0a5f54;
            color: white;
            border-radius: 10px 10px 0px 0px;
            display:flex;
            align-items: center;
        }
        .wa-chat-box-brand{
            margin-left: 20px;
            width: 50px;
            height: 50px;
            border-radius: 25px;
            box-shadow: 2px 2px 6px rgba(0,0,0,0.4);
        }
        .wa-chat-box{
            background-color:white;
            z-index: 16000160 !important;
            margin-bottom: 60px;
            width: 360px;
            position: fixed !important;
            bottom: 50px !important;
            right : 50px;
            border-radius: 10px;
            box-shadow: 2px 2px 6px rgba(0,0,0,0.4);
            font: 400 normal 15px/1.3 -apple-system, BlinkMacSystemFont, Roboto, Open Sans, Helvetica Neue, sans-serif;
        }
        #wa-widget-send-button {
            margin: 0 0 50px 0 !important;      
            padding-left: 0;
            padding-right: 0;
            position: fixed !important;
            z-index: 16000160 !important;
            bottom: 0 !important;
            text-align: center !important;
            height: 50px;
            min-width: 50px;
            border-radius: 25px;
            visibility: visible;
            transition: none !important;
            background-color: #4dc247;
            box-shadow: 2px 2px 6px rgba(0,0,0,0.4);
            right: 50px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content:center;
        }
        .wa-messenger-svg-whatsapp{
            fill: white;
            width: 41px;
            height: 50px;
            stroke: none;
        }
        .wa-chat-box-poweredby{
            text-align: center;
            font: 400 normal 15px/1.3 -apple-system, BlinkMacSystemFont, Roboto, Open Sans, Helvetica Neue, sans-serif;
            margin-bottom: 15px;
            margin-top: -10px;
            font-style: italic;
            font-size: 12px;
            color: lightgray;
        }
        @media only screen and (max-width: 600px) {
            .wa-chat-box
            {
                width: auto;
                position: fixed !important;
                right: 20px!important;
                left: 20px!important;
            }
        }