.MainPayment {
  min-height: 100vh;
  .shortDesc {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
    overflow: hidden;
  }
  .payment {
    border-radius: 20px;
    background: #fff;
    border: 1px solid #f5f5f5;
    filter: drop-shadow(0px 3px 7px rgba(203, 203, 203, 0.41));
    img {
      cursor: pointer;
    }
  }
  .Donated {
    position: relative;
    .path_Donated {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.MainPayment:lang(ar) {
  font-family: "Cairo", sans-serif;
  direction: rtl;
  text-align: right;
}
