@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;700&display=swap");

html {
  scroll-behavior: smooth;
}

@media(max-width: 992px) {
  .content-title {
    text-align: center !important;
  }
}

.bigger-font {
  font-size: 16px;
}

.text-style {
  color: #7a828b;
  font-weight: bold;
}

body {
  .title-section {
    position: relative;
    .content-title {
      position: absolute;
      top: 25%;
      left: 0;
      right: 0;
      text-align: center;
    }
    .content-title:lang(ar) {
      right: 0;
    }
  }

  .title-section:lang(ar) {
    direction: rtl;
    text-align: right;
  }
  .title-section-center {
    position: relative;
    .content-title {
      position: absolute;
      top: 25%;
      left: 0;
      right: 0;
      text-align: center;
    }
  }
  .project_cost {
    color: #28a745;
  }
  .project_remaining {
    color: #a1a81d;
  }
  .shortDesc {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
    overflow: hidden;
  }
  .projectTitle {
    font-weight: 800;
  }
  .projectDescription {
    color: #7a828b;
    font-weight: bold;
  }
  .sectionTitle {
    font-weight: 600;
  }
  .line-clamp, .line-clamp-2, .line-clamp-1 {
    display: -webkit-box;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
  .line-clamp {
    -webkit-line-clamp: 3;
  }
  .line-clamp-1 {
    -webkit-line-clamp: 1;
  }
  .line-clamp-2 {
    -webkit-line-clamp: 2;
  }
  .small-title {
    font-size: 14px;
  }
  .bold-text {
    font-weight: bold;
  }
  .title-section-center:lang(ar) {
    position: relative;
    .content-title {
      position: absolute;
      top: 25%;
      left: 0;
      right: 0;
    }
  }
  .title-section-center-slider {
    position: relative;
    .content-title-slider {
      position: absolute;
      top: 25%;
      left: 0;
      right: 0;
    }
  }
  .title-section-center-slider:lang(ar) {
    position: relative;
    .content-title-slider {
      position: absolute;
      top: 25%;
      left: 0;
      right: 0;
    }
  }
}

.bg-success {
  // background: #F8F103 !important;
}
.bg-light-success {
  background: #dae139 !important;
}
.rounded {
  border-radius: 20px !important;
}
.btn-outline-facebook {
  transition: all 0.5s ease-in-out;
  border: 1px solid #3b5998;
  color: #3b5998;
  &:hover {
    transition: all 0.5s ease-in-out;
    border: 1px solid #3b5998;
    background: #3b5998;
    color: #fff;
  }
}
.btn-outline-twitter {
  transition: all 0.5s ease-in-out;
  border: 1px solid #1da1f2;
  color: #1da1f2;
  &:hover {
    transition: all 0.5s ease-in-out;
    border: 1px solid #1da1f2;
    background: #1da1f2;
    color: #fff;
  }
}
.btn-outline-instagram {
  transition: all 0.5s ease-in-out;
  border: 1px solid #c13584;
  color: #c13584;
  &:hover {
    transition: all 0.5s ease-in-out;
    border: 1px solid #c13584;
    background: #c13584;
    color: #fff;
  }
}
.btn-outline-linkedin {
  transition: all 0.5s ease-in-out;
  border: 1px solid #0077b5;
  color: #0077b5;
  &:hover {
    transition: all 0.5s ease-in-out;
    border: 1px solid #0077b5;
    background: #0077b5;
    color: #fff;
  }
}

.btn-outline-whatsapp {
  transition: all 0.5s ease-in-out;
  border: 1px soli#24A417;
  color: #24a417;
  &:hover {
    transition: all 0.5s ease-in-out;
    border: 1px solid #24a417;
    background: #24a417;
    color: #fff;
  }
}

.btn-success {
  border-radius: 10px;
  background: #fff !important;
  border: 1px solid #28a745 !important;
  color: #28a745 !important;
  transition: all 0.5s ease-in-out;

    &:hover {
      transition: all 0.5s ease-in-out;
      background: #28a745 !important;
      border: 1px solid #28a745 !important;
      color: #fff !important;
    }
  
  &.fast-donate {
    background: #28a745 !important;
    color: #fff !important;
    
    span {
      font-weight: 500;
    }
    
    &:hover {
      transition: all 0.5s ease-in-out;
      background: #fff !important;
      border: 1px solid #28a745 !important;
      color: #28a745 !important;
    }
  }
}

.bg-worning {
  background: #f8f103 !important;
}

.text-light-success {
  color: #dae139;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgba(40, 167, 69, 12);
}

a:hover {
  text-decoration: none;
}

// Arrow
.arrow {
  position: absolute;
  top: 80%;
  left: 6%;
  transform: translate(-50%, -50%);
}
.arrow span {
  display: block;
  width: 20px;
  height: 20px;
  border-bottom: 2px solid #28a745;
  border-right: 2px solid #dae139;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}
.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}
.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}
@media (max-width: 600px) {
  .title-section-center-slider:lang(ar) {
    position: relative;
    .content-title-slider {
      position: absolute;
      top: 17%;
    }
  }
}
@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-small {
  font-size: 12px;
}

.direction-rtl {
  direction: rtl;
}

.direction-ltr {
  direction: ltr;
}

.not-found {
  height: 50vh;
  text-align: center;
}

.not-found__title {
  margin: 0;
  font-size: 100px;
  color: rgb(122, 130, 139);
}

.slider-wrapper {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}

.carousel-inner {
  max-height: 500px;
}

.content-title-slider:lang(ar) {
  margin-right: 50px;
}

.reach-us {
  font-size: 16px;

  a {
    padding: 15px;
    color: white;
    text-align: center !important;
    width: auto;
    border-radius: 25px;
    visibility: visible;
    transition: none !important;
    background-color: #4dc247;
    cursor: pointer;
    
    i {
      font-size: 20px;
    }
  }
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1400px !important;
}

span[class*="indicatorSeparator"] {
  display: none;
}

div[class*="indicatorContainer"] {
  padding: 0;
}

div[class*="ValueContainer"] {
  height: 38px;
}

div[class*="menu"] {
  z-index: 1111;
}

.select-options,
.category-input {
  div[class*="control"] {
    width: 100%;
  }  
}

.mobile-select {
  div[class*="control"] {
    width: 100px !important;
  }  
}

div[class*="control"] {
  width: 60px;
}

.mt-num {
  margin-top: 2.5rem;
}

.shortcut-box {
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 10px;
}

.share-project {
  position: absolute;
  right: 0;
  top: 0;
  background: rgba(0,0,0,.2);
  color: #fff;
  border-radius: 0 0 0 10px;
  padding: 5px;
}

.slider:lang(ar) {
  font-family: "Cairo", sans-serif;
}

/* donateInput */
.donate-group {
  border: 2px solid #eee;
  border-radius: 20px;
}

.donate-group__input {
  border: none;
  background: transparent;
  overflow: hidden;

  &:focus,
  &:active {
    border: none;
    outline: unset;
    box-shadow: none;
    overflow: hidden;
    background: transparent;
  }
}

/* donateInput */

/* project new card */

.related-projects {
  .project {
    margin: 20px 0;
  }
}

.project {
  border: 1px solid #eee;
  margin-bottom: 30px;
  margin: 0 20px 30px 20px;
}

.projectPhoto {
  background-size: cover;
  position: relative;
  overflow: hidden;

  img {
    transition: all .2s ease-in-out;
    max-height: 300px;

    &:hover {
      transform: scale(1.1);
    }
  }
}

.projectShare {
  position: absolute;
  right: 0;
  top: 0;
  background: rgba(0,0,0,.4);
  color: #fff;
  border-radius: 0 0 0 10px;
  z-index: 1;
  
  &.share-block {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: transparent;

    a {
      float: right;
      padding: 5px 10px;
      color: #fff;
      font-size: 30px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      &.tws {
        background: #55acee;
      }

      &.fbs {
        background: #3b5998;;
      }

      &.gps {
        background: #dc4e41;
      }

      &.was {
        background: #25d366;
      }
    }
  }

  span {
    background: rgb(245, 162, 50);
    float: right;
    padding: 5px 10px;
    font-size: 18px;
  }

  a {
    float: right;
    padding: 5px 10px;
    color: #fff;
    font-size: 18px;

    &.tws:hover {
      background: #55acee;
    }

    &.fbs:hover {
      background: #3b5998;;
    }

    &.gps:hover {
      background: #dc4e41;
    }

    &.was:hover {
      background: #25d366;
    }
  }
}

.projectTitle {
  left: 0;
  bottom: 0;
  background: rgb(116, 202, 190);
  color: #fff;
  right: 0;

  a {
    color: #fff;
    display: block;
    padding: 10px;
    text-align: center;
  }
}

.projectInputs {
  padding: 15px;
  min-height: 135px;
}

.projectActions {
  padding: 15px;
  background: #f3f3f3;
}

.shortcut-square {
  border: 1px solid #eee;
  padding: 8px 12px;
  cursor: pointer;
  background: rgb(116, 202, 190);
  color: #fff;

  &:hover {
    color: rgb(116, 202, 190);
    background: #fff;
  }
}

/* project new card */

.fs-30 {
  font-size: 30px;
}

/* about */
img {
  max-width: 100%;
}
/* about */

.carousel-control-prev,
.carousel-control-next {
  opacity: 1 !important;
  width: 5% !important;
}
.carousel-control-prev-icon, 
.carousel-control-next-icon {
    width: 25px;
    height: 35px;
    background-color: black;
}
