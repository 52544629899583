.Terms {
  min-height: 100vh;
  background: url(../../img/bg_about.svg) center center no-repeat;
  .item {
    border-radius: 20px;
    background: #fff;
    border: 1px solid #f5f5f5;
    filter: drop-shadow(0px 3px 7px rgba(203, 203, 203, 0.41));
  }
}
