.ProjectComPage {
  min-height: 100vh;
  .nav-pills {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65px;
    padding: 0;
    .nav-link.active,
    .nav-pills .show > .nav-link {
      background-color: #28a745 !important ;
    }
  }
  @media (max-width: 500px) {
    .statusWrapper {
      display: flex;
      justify-content: center;
    }
    .nav-pills {
      height: auto;

      .nav-item {
        margin: 4px;
      }
    }
  }
}

.ProjectDetails {
  background: url(../../img//bg_details_Project.svg) center center no-repeat;
  .carousel-item {
    img {
      border-radius: 20px;
      max-height: 500px;
    }
  }
  .InfoProject {
    border-radius: 20px;
    background: #fff;
    border: 1px solid #f5f5f5;
    filter: drop-shadow(0px 3px 7px rgba(203, 203, 203, 0.41));
    .county-name {
      width: 46px;
      height: 46px;
    }
    .iframe-container {
      position: relative;
      width: 100%;
      padding-bottom: 56.25%;
      height: 0;
    }
    .iframe-container iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .PublisherInfo {
    border-radius: 20px;
    filter: drop-shadow(0px 3px 7px rgba(203, 203, 203, 0.41));
    position: relative;
    background: #fff;
    .polygon {
      position: absolute;
      top: 10%;
      z-index: -1;
    }
    .path_bottom {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: -1;
    }
  }
  .note {
    border-radius: 20px;
  }
  .costPayment {
    border-radius: 20px;
    background: rgb(86, 120, 0);
    background: linear-gradient(
      45deg,
      rgba(86, 120, 0, 1) 0%,
      rgba(218, 225, 57, 1) 100%
    );
  }
  .SelectDonation {
    border-radius: 20px;
    background: #fff;
    border: 1px solid #f5f5f5;
    filter: drop-shadow(0px 3px 7px rgba(203, 203, 203, 0.41));
  }
  .ItemDonation {
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    border: 1px solid transparent;
    img {
      transition: all 0.5s ease-in-out;
      transform: rotate(0deg);
    }
    &:hover {
      border: 1px solid #ccc;
      img {
        transition: all 0.5s ease-in-out;
        transform: rotate(360deg);
      }
    }
    .price {
      font-size: 14px;
      border-radius: 10px !important;
      background: #28a745;
      box-shadow: 0px 3px 6px rgba(86, 120, 0, 0.4);
    }
  }
  .ItemDonation:lang(ar) {
    img {
      transition: all 0.5s ease-in-out;
      transform: rotate(180deg);
    }
    &:hover {
      border: 1px solid #ccc;
      img {
        transition: all 0.5s ease-in-out;
        transform: rotate(-180deg);
      }
    }
  }
  .ShareButtons {
    border-radius: 20px;
    background: #fff;
    border: 1px solid #f5f5f5;
    filter: drop-shadow(0px 3px 7px rgba(203, 203, 203, 0.41));
  }
}

.ProjectComPage:lang(ar) {
  direction: rtl;
  text-align: right;
  font-family: "Cairo", sans-serif;
}

.ProjectDetails:lang(ar) {
  font-family: "Cairo", sans-serif;
  direction: rtl;
  text-align: right;
  .ShareButtons {
    .icon-wrapper {
      margin-right: 40px;
    }
  }
}