.mobile-menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.menu {
  position: relative;
  margin: 10px 0;
  background: url(../img/path_tree.png) -100px 10px no-repeat;
}

.menu__item {
  font-family: "Quicksand", sans-serif;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  padding: 5px 0;
  color: #333;
  text-align: left;
}

.menu__item-name {
  font-size: 1.5em;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  padding: 0.5em 0 0 0;
  white-space: pre;
  text-transform: capitalize;
}

.menu--ama .menu__item-name span {
  display: inline-block;
}

.menu--ama .menu__item {
  z-index: 9999;
  &:hover {
    .menu__item-name {
      &::after {
        transform: scale3d(1, 1, 1);
      }
    }
  }
}
