.WhyAreWe {
  background-size: cover;

  .item {
    width: 100%;
    height: 270px;
    @media (max-width: 576px) {
      height: auto;
    }
    border-radius: 180px 70px 120px 70px;
    background: #fff;
    box-shadow: 0px 1.5px 27px rgba(86, 120, 0, 0.27);
    transition: all 0.5s ease-in-out;
    &::before {
      transition: all 0.5s ease-in-out;
      content: "";
      border: 0.5px solid #28a745;
      height: 50px;
      display: block;
      position: absolute;
      right: 14px;
      top: calc((270px - 50px) / 2);
    }
    img {
      @media (max-width: 576px) {
        margin-left: -10px !important;
      }
    }
  }
}
@media (max-width: 500px) {
  .aidsMobile {
    display: flex;
    justify-content: center;
  }
  .WhyAreWe:lang(ar) {
    .aidsContentWrapper {
      margin-right: 60px;
    }
  }
  .WhyAreWe:lang(en) {
    .aidsContentWrapper {
      margin-left: 10px;
      margin-bottom: 20px;
      text-align: center;
    }
  }
}
.WhyAreWe:lang(ar) {
  .aidsPic {
    direction: ltr;
    text-align: center;
  }
  .content-title-slider:lang(ar) {
    margin-left: 0px;
    padding-left: 0px;
    margin-right: 20px;
  }
  direction: rtl;
  text-align: right;
  .aidsTitle {
    font-weight: 800;
    font-family: "Cairo", sans-serif;
  }
  font-family: "Cairo", sans-serif;
  .big-wrapper {
    direction: rtl;
    text-align: right;
  }
}
